import React from 'react'
import logo from './images/companylogo.jpg'
import './TopBanner.css'

export default function TopBanner() {
    return (
        <div className="TopBanner">
            <img src={logo} alt="Company Logo" />
        </div>
    )
}

