/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './Markers.css'
import pickup from './images/pickup.png'
import delivery from './images/delivery.png'

class Markers extends Component {

    render() {
        const { routingData } = this.props
        return (
            <div className="row">
                <div className="col-3">
                    <p className="marker-area"> <img src={pickup} alt="pickup" className="icon" /><strong>Pickup</strong></p>
                    <p className="marker-text-area">{routingData.originCity}, {routingData.originState} {routingData.originZipcode}</p>
                </div>
                <div className="col-3 delivery">
                    <p className="marker-area"><img src={delivery} alt="delivery" className="icon" /><strong>Delivery</strong></p>
                    <p className="marker-text-area">{routingData.destinationCity}, {routingData.destinationState} {routingData.destinationZipcode}</p>
                </div>
            </div>
        );
    }
}

export default Markers;