import React from 'react';
import "./Footer.css"

export default function Footer() {
    return (
        <div id="footer">
            <p>Please send any issues or feedback to <a href="mailto:eharrison@arrivelogistics.com?Subject=Quick%20Tracking%20:%20">Eric Harrison</a></p>
        </div>
    )
}
