import React, { Component } from 'react';
import axios from 'axios';
import InfoDisplay from "./InfoDisplay";
import SearchField from "./SearchField";
import Map from "./Map";
import Results from "./Results";


class Body extends Component {
    state = { loadNumber: "", submitValue: "", results: "", hiddenMap: false, tableData: "", routingData: "", value: "" };

    handleChange = (event) => {
        this.setState({ loadNumber: event.target.value, value: event.target.value });
    }

    handleSubmit = (event) => {
        this.setState({ value: null });
        this.getResults(this.state.loadNumber);
        event.preventDefault();

    }

    getResults = (loadNumber) => {
        axios.post('/api', { loadNumber: loadNumber })
            .then(res => {
                if (res.data.routingInfo) {
                    this.setState({ hiddenMap: false })
                    this.createMap(res.data.routingInfo);
                } else {
                    this.setState({ hiddenMap: true })
                }
                this.setState({ loadNumber: res.data.loadNumber, submitValue: res.data.loadNumber, results: res.data.trackingInfo, routingData: res.data.routingInfo });
            })
            .catch();
    }

    createMap = (data) => {
        const mapArea = document.querySelector("#map");
        mapArea.innerHTML = "";
        mapArea.className = "";

        window.ALKMaps.APIKey = "2c69108527364b45842911eafef4687a";
        const map = new window.ALKMaps.Map("map");
        const layer = new window.ALKMaps.Layer.BaseMap(
            "ALK Maps",
            {},
            { displayInLayerSwitcher: false }
        );
        const routingLayer = new window.ALKMaps.Layer.Routing("Route Layer");
        map.addLayer(layer);
        map.setCenter();
        map.addLayer(routingLayer);

        for (let control of map.controls) {
            control.deactivate();
        }

        const coords = [
            new window.ALKMaps.LonLat(data.originCityLongitude, data.originCityLatitude),
            new window.ALKMaps.LonLat(data.destinationCityLongitude, data.destinationCityLatitude),
        ];
        const stops = window.ALKMaps.LonLat.transformArray(
            coords,
            new window.ALKMaps.Projection("EPSG:4326"),
            map.getProjectionObject()
        );
        routingLayer.addRoute({
            stops,
            functionOptions: {
                routeId: "app-map",
            },
            routeOptions: {
                routingType: "Practical",
            },
            reportOptions: {
                dataVersion: "Current",
            },
        });
    };


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-6">
                        <InfoDisplay />
                        <SearchField
                            loadNumber={this.state.loadNumber}
                            value={this.state.value || ""}
                            handleChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                        />
                    </div>

                    <div className="col-6">
                        <Map className="center" hidden={this.state.hiddenMap || false} />
                    </div>
                </div>

                {!!this.state.results &&
                    <Results
                        hidden={this.state.submitValue}
                        loadNumber={this.state.submitValue}
                        tableData={this.state.results}
                        routingData={this.state.routingData}
                    />}
            </div>

        );
    }
}

export default Body;