/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import './Results.css'
import Markers from './Markers'

class Results extends Component {


    createTableBody = (data) => {
        if (data) {
            return data.map((row, index) => {
                const { status, time, location } = row
                return (
                    <tr key={index}>
                        <td>{status}</td>
                        <td>{time}</td>
                        <td>{location}</td>
                    </tr>
                );
            })
        }
    }

    render() {
        return (
            <div className='table-area'>
                <div hidden={this.props.hidden === ""} id="table-area">
                    <p >Tracking History for Load# {this.props.loadNumber}</p>
                    <Markers routingData={this.props.routingData} />
                    <hr />
                    <div className="table-fixed-head">
                        <table className="table table-sm table-striped table-borderless">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Time</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody className='container-fluid'>
                                {this.createTableBody(this.props.tableData)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default Results;