import React from 'react'
import './InfoDisplay.css'

export default function InfoDisplay() {
    return (
        <div className="InfoDisplay">
            <p id="title">Load Tracking</p>
            <p id="body">{"Enter your Load Number below to see your load's tracking history"}</p>
        </div>
    )
}
