/* eslint-disable react/prop-types */
import React, { Component } from 'react'

import "./SearchField.css"

export default class SearchField extends Component {
    render() {
        return (
            <div>
                <div></div>
                <form onSubmit={this.props.handleSubmit} autoComplete="off">
                    <div className="floating-label">
                        <input type="text" value={this.props.loadNumber || ""} onChange={this.props.handleChange} name="search" id="search" placeholder="Load #" />
                        <label htmlFor="search"> Load # </label>
                    </div>
                    <div id="submit-button">
                        <input className="btn btn-primary" type="submit" value="Track" disabled={!this.props.value} />
                    </div>
                </form>

            </div>
        );
    }
}