/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import "./Map.css"

class Map extends Component {
    render() {

        return (
            <div id="map" hidden={this.props.hidden}>
            </div>
        );
    }
}

export default Map;
