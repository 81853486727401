import React from "react";
import "./App.css";
import TopBanner from './components/TopBanner';
import Body from "./components/Body";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="container shadow" id="main-container">
      <TopBanner />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
